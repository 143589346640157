import React from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";

const Step2 = ({
  setReportType,
  reportTypes,
  reportType,
  serviceUser,
  setBehaviourReportSelections,
  behaviourReportSelections,
}) => {
  const getIconColour = (type) => {
    if (!type.available) {
      return theme.neutral3;
    }
    return reportType.id === type.id ? theme.neutral7 : theme.neutral5;
  };

  const fillPlaceholderWithSUName = (string) => {
    return string.replace("<placeholder_name>", serviceUser?.label);
  };

  return (
    <div className={styles.reportType}>
      <h2>Select a report type</h2>
      <div className={styles.reportType_row}>
        <div className={styles.newReportContainer}>
          <div className={styles.reportTypeContainer}>
            {reportTypes.map((type, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    reportType.id === type.id
                      ? styles.focusedReportTypeButton
                      : styles.reportTypeButton
                  } ${type.available ? styles.available : styles.unavailable}`}
                  onClick={() => {
                    type.available && setReportType(type);
                  }}
                >
                  <type.icon colour={getIconColour(type)} />

                  <div className={styles.reportTypeButtonTitle}>
                    {type.title}
                  </div>
                  {!type.available && (
                    <div className={styles.reportType_comingSoon}>
                      (coming soon)
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.whatsIncludedContainer}>
          <div className={styles.title}>What is included in this report?</div>
          <div className={styles.horizontalBar} />
          {reportType.id !== "general-report" && (
            <>
              <div className={styles.subheading}>
                This report type will provide you with evidence to the questions
                below.
              </div>
              {reportType.sections?.map((section) => {
                return (
                  <div className={styles.section}>
                    <div className={styles.sectionTitle}>
                      {fillPlaceholderWithSUName(section.title)}
                    </div>
                    <ol className={styles.list}>
                      {section.questions?.map((item) => (
                        <li className={styles.item}>
                          {fillPlaceholderWithSUName(item)}
                        </li>
                      ))}
                    </ol>
                  </div>
                );
              })}
            </>
          )}
          {reportType.id === "general-report" && (
            <>
              <div className={styles.subheading}>
                Select which behaviours you would like included in this report:
              </div>
              <div className={styles.options}>
                {behaviourReportSelections.map((behaviour) => (
                  <div className={styles.option}>
                    <input
                      type="checkbox"
                      id={behaviour.name} // => { 'sustenance' : true } => ['sustenance'] => 'sustenance'
                      name={behaviour.name}
                      checked={behaviour.selected} // => { 'sustenance' : true } => [true] => "true" => true / false (boolean)
                      value={behaviour.selected}
                      onChange={() => {
                        setBehaviourReportSelections((prev) =>
                          prev.map((b) =>
                            b.name === behaviour.name
                              ? { ...b, selected: !b.selected }
                              : b
                          )
                        );
                      }}
                    />
                    <p>{behaviour.name}</p>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Step2;
