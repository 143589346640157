import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { mobileBreakpoint } from "../../../Services/config";
import theme from "../../../Styles/theme.scss";
import { useViewport } from "../../../Components/ViewportProvider";
// State
import { useDispatch, useSelector } from "react-redux";
import {
  setInitialSensors,
  setSensors,
} from "../../../State/slices/sensors-slice";
// Components
import Page from "../../../Components/Page";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationNextButton from "../../../Components/InstallationNextButton";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import Select from "react-select"; // https://react-select.com/home#getting-started
// Constants
import { INITAL_SENSORS } from "../../../Services/Constants";
import { setSensorLocations } from "../../../State/slices/sensorLocations-slice";

const Install = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useViewport();
  const isDesktop = width > mobileBreakpoint;

  // Local state
  const [serviceUserSelected, setServiceUserSelected] = useState(
    location.serviceUser
  );
  const startedInstallation =
    serviceUserSelected?.installationStatus === "started";

  // redux state
  const dispatch = useDispatch();
  const serviceUsers = useSelector((state) => state.serviceUsers);

  const modifiedSUs = serviceUsers
    .filter(
      (serviceUser) =>
        serviceUser.serviceUserProperties?.installationStatus !== "complete"
    )
    .map((serviceUser) => ({
      value: serviceUser.id,
      label: `${serviceUser.userstring} ${
        serviceUser.serviceUserProperties?.installationStatus === "started"
          ? "(in progress)"
          : ""
      }`,
      // Depending on whether the user is a manager or just a OT the postcode and house number are in slightly different places
      postcode:
        serviceUser.postcode || serviceUser.serviceUserProperties?.postcode,
      houseNumber:
        serviceUser.houseNumberOrName ||
        serviceUser.serviceUserProperties?.houseNumberOrName,
      devicestring: serviceUser.devicestring,
      installationStatus: serviceUser.serviceUserProperties?.installationStatus,
    }));

  const onSelectNotStartedSU = () => {
    // Reset persisted sensor status in redux so that pervious installs do not interfere with new ones
    dispatch(setInitialSensors(INITAL_SENSORS));
    dispatch(setSensors(null));
    dispatch(setSensorLocations(null));
  };

  const onSelectInProgressSU = () => {
    // Reset persisted sensor status in redux so that pervious installs do not interfere with new ones
    dispatch(setInitialSensors(INITAL_SENSORS));
    navigate(
      `/install/sensors/${serviceUserSelected.value}/${serviceUserSelected.devicestring}`
    );
  };

  const onConfirmSUDetails = () => {
    navigate(`/install/link/${serviceUserSelected.value}`);
  };

  const suAddress =
    serviceUserSelected &&
    serviceUserSelected?.houseNumber &&
    serviceUserSelected?.postcode
      ? [serviceUserSelected?.houseNumber, serviceUserSelected?.postcode]
      : ["No address found"];

  return (
    <Page className={styles.page}>
      <InstallationCard>
        <div className={styles.card_content}>
          <img
            src="/images/installation/lilli-installation-graphic.png"
            alt="Lilli"
            className={styles.logo}
          />

          <h2 className={styles.select_SU_header}>Welcome!</h2>
          <p>Select which Lilli user you are installing for:</p>
          <label data-private>
            <p className={styles.selectLabel}>Select Lilli user</p>
            <Select
              placeholder="Select"
              options={modifiedSUs}
              name="serviceUsers"
              value={serviceUserSelected}
              onChange={setServiceUserSelected}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null, // Removes the "|" to the left of the drop-down arrow
              }}
              styles={{
                control: (baseStyles, { isFocused }) => ({
                  ...baseStyles,
                  borderColor: isFocused ? theme.primary3 : theme.neutral3,
                  marginTop: 4,
                  fontSize: theme.bodyMedium,
                  color: theme.neutral7,
                  borderRadius: 8,
                  ":hover": {
                    borderColor: theme.primary3,
                  },
                  boxShadow: "none",
                }),
                option: (styles, { isFocused }) => ({
                  ...styles,
                  fontSize: "16px",
                  backgroundColor: isFocused ? theme.neutral2 : null,
                  color: theme.neutral7,
                  // adjust background colour when mouse button is pressed
                  ":active": {
                    backgroundColor: theme.neutral3,
                  },
                }),
              }}
            />
          </label>

          {serviceUserSelected && suAddress.length > 0 ? (
            <div data-private className={styles.confirm_card}>
              {suAddress.length > 0 &&
                suAddress?.map((line, index) => (
                  <div key={index} className={styles.confirm_address}>
                    {line}
                  </div>
                ))}
            </div>
          ) : null}

          {serviceUserSelected?.devicestring && startedInstallation && (
            <div className={styles.continueInstallation}>
              <p>
                A previous attempt was made to install a Lilli kit (serial
                number {serviceUserSelected.devicestring}).
              </p>
              <p>
                Would you like to continue the installation or start with a new
                kit?
              </p>
            </div>
          )}

          <div className={styles.buttons}>
            {startedInstallation && serviceUserSelected?.devicestring && (
              <InstallationGoBackButton
                isDesktop={startedInstallation && isDesktop}
                onClick={onSelectInProgressSU}
                label="Continue installation"
              />
            )}
            <InstallationNextButton
              isDesktop={startedInstallation && isDesktop}
              onClick={() => {
                onSelectNotStartedSU();
                onConfirmSUDetails();
              }}
              disabled={!serviceUserSelected}
              label={startedInstallation ? "Install new kit" : "Next"}
            />
          </div>
        </div>
      </InstallationCard>
    </Page>
  );
};

export default Install;
