import React, { useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
// Hooks
import {
  useFade,
  useOpenHubPairing,
  useGetSensorStatus,
} from "../../../Services/Hooks";
// State
import { useSelector } from "react-redux";
// Routing
import { useParams, useNavigate } from "react-router-dom";
// New Components
import ConfirmExitInstall from "../../../Components/InstallationNew/ConfirmExitInstall";
import SensorCard from "../../../Components/InstallationNew/SensorCard";
import SensorsHeader from "../../../Components/InstallationNew/SensorsHeader";
import SensorSquares from "../../../Components/InstallationNew/SensorSquares";
import MobileDrawer from "../../../Components/MobileDrawer";
import SensorGuide from "../../../Components/InstallationNew/SensorGuide";
// Components
import Page from "../../../Components/Page";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationNextButton from "../../../Components/InstallationNextButton";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import NewStyleModal from "../../../Components/NewStyleModal";
import { useViewport } from "../../../Components/ViewportProvider";
// Constants
import {
  INSTALL_INSTRUCTIONS,
  INITAL_SENSORS,
} from "../../../Services/Constants";

const InstallSensorsNew = () => {
  const { devicestring, id } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();
  const [engageContinuousPolling, setEngageContinuousPolling] = useState(false);
  const [showMissingLocationMessage, setShowMissingLocationMessage] =
    useState(false);
  const [showNotAllConnectedMessage, setShowNotAllConnectedMessage] =
    useState(false);

  // sizing
  const { width } = useViewport();
  const isDesktop = width >= 900;
  const isLessThan450px = width <= 450;
  const sensorLocations = useSelector((state) => state.sensorLocations);

  const [connectionsChecked, setConnectionsChecked] = useState({
    p1: false,
    m1: false,
    m2: false,
    m3: false,
    d1: false,
    d2: false,
  });

  // Trigger the sensor status fetching
  const { error, hubIsOnline, sensors, loading, fetchSensorStatus } =
    useGetSensorStatus({
      devicestring: devicestring,
      id: id,
      continuous: engageContinuousPolling,
    });
  const allSensorsOnline = sensors?.devices
    ? sensors?.devices?.filter(
        (device) => !device?.name?.includes("REMOVED") && !device?.online
      ).length === 0
    : false;

  // Trigger the hub to activate pairing mode if the installation is not yet complete
  useOpenHubPairing({
    hubId: id,
    installationComplete: allSensorsOnline,
    frequencyOfRepairing: 60000,
  });

  // Which sensor to show in the SensorCard component
  const [selectedSensor, setSelectedSensor] = useState(
    sensors?.devices?.[1] || INITAL_SENSORS.devices[1]
  );

  // Helper variables
  const nonHubSensors = sensors?.devices?.filter((i) => i.label !== "h1");
  const instructions = INSTALL_INSTRUCTIONS[selectedSensor?.label];
  const isMotion = selectedSensor?.label?.includes("m") || false;
  const sensorsMissingLocations = sensors?.devices?.filter(
    (device) =>
      !device.name.includes("REMOVED") &&
      device.locationRequired &&
      !sensorLocations?.[device.name]
  );
  const sensorNamesWithoutLocations = [...sensorsMissingLocations].map((i) =>
    i?.label.toUpperCase()
  );
  const installationComplete =
    sensorNamesWithoutLocations.length === 0 && hubIsOnline && allSensorsOnline;
  const notAllConnected = !hubIsOnline || !allSensorsOnline;
  const missingSensorLocations = sensorNamesWithoutLocations.length > 0;

  /* Which sensors have had their connections checked? */
  const updateConnectionsChecked = (sensor) => {
    setConnectionsChecked((prevState) => ({
      ...prevState,
      [sensor?.label]: true, // we've checked the connection of this sensor
    }));
  };

  /* Modals */
  // Exit install?
  const [isExitVisible, setShowExitAnimation, showExitAnimation] = useFade(
    false,
    150
  );
  // Installation guide
  const [isGuideVisible, setShowGuideAnimation, showGuideAnimation] = useFade(
    false,
    150
  );

  return (
    <Page className={styles.page} cypresstestid="Installation-Sensors-8">
      <InstallationCard style={styles.card}>
        <div className={styles.card_top}>
          <div className={styles.desktop_right}>
            {!isDesktop && <SensorsHeader isHub={false} loading={loading} />}
            {!isDesktop && (
              <SensorSquares
                selectedSensor={selectedSensor}
                sensors={nonHubSensors}
                onClick={setSelectedSensor}
              />
            )}
            <SensorCard
              error={error}
              sensor={selectedSensor}
              sensors={nonHubSensors}
              showMissingLocationMessage={showMissingLocationMessage}
              sensorLocations={sensorLocations}
              loading={loading}
              onConnect={() => {
                if (!hubIsOnline) {
                  setEngageContinuousPolling(true);
                } else {
                  fetchSensorStatus();
                }
                updateConnectionsChecked(selectedSensor);
              }}
              onViewGuide={() => {
                setShowGuideAnimation(true);
              }}
              connectionsChecked={connectionsChecked}
            />
          </div>
          <div className={styles.desktop_left}>
            {isDesktop && <SensorsHeader isHub={false} loading={loading} />}
            {isDesktop && (
              <SensorSquares
                selectedSensor={selectedSensor}
                sensors={nonHubSensors}
                onClick={setSelectedSensor}
              />
            )}
            <div className={styles.cannot_complete_install_message}>
              {showMissingLocationMessage && missingSensorLocations
                ? `Please select locations for the following sensors: ${sensorNamesWithoutLocations.join(
                    ", "
                  )}`
                : notAllConnected && showNotAllConnectedMessage
                  ? "Not all sensors are connected"
                  : null}
            </div>
          </div>
        </div>
        <div className={styles.bottom_buttons}>
          <InstallationNextButton
            disabled={loading}
            onClick={
              loading
                ? () => {}
                : installationComplete
                  ? () => navigate(`/install/finish/${id}/${devicestring}`)
                  : notAllConnected
                    ? () => setShowNotAllConnectedMessage(true)
                    : missingSensorLocations
                      ? () => setShowMissingLocationMessage(true)
                      : () => {}
            }
            style={{
              borderRadius: "8px",
              lineHeight: 1.75,
              marginBottom: isDesktop ? "0px" : "16px",
            }}
            label="Complete install"
          />
          <InstallationGoBackButton
            onClick={() => setShowExitAnimation(true)}
            style={{
              borderRadius: "8px",
              lineHeight: 1.75,
              backgroundColor: "white",
              border: `1px solid ${theme.primary3}`,
            }}
            marginRight={isLessThan450px ? "0px" : "16px"}
            marginTop={isLessThan450px ? "16px" : "0px"}
            label="Exit install"
          />
        </div>
      </InstallationCard>
      {isGuideVisible && isDesktop ? (
        <NewStyleModal
          hide={() => {
            setShowGuideAnimation(false);
          }}
          showCloseIcon={true}
          useFade={false}
          showAnimation={showGuideAnimation}
          size={"medium"}
        >
          <SensorGuide
            isDesktop={isDesktop}
            instructions={instructions}
            isMotion={isMotion}
            setShowGuideAnimation={setShowGuideAnimation}
          />
        </NewStyleModal>
      ) : (
        <MobileDrawer
          closeModal={() => {
            setShowGuideAnimation(false);
          }}
          showGuide={showGuideAnimation}
        >
          <SensorGuide
            isDesktop={isDesktop}
            instructions={instructions}
            isMotion={isMotion}
            setShowGuideAnimation={setShowGuideAnimation}
            isGuideVisible={isGuideVisible}
          />
        </MobileDrawer>
      )}
      {isExitVisible && (
        <NewStyleModal
          hide={() => {
            setShowExitAnimation(false);
          }}
          useFade={true}
          showAnimation={showExitAnimation}
        >
          <ConfirmExitInstall
            setShowAnimation={setShowExitAnimation}
            setExitInstall={() => navigate("/install")}
          />
        </NewStyleModal>
      )}
    </Page>
  );
};

export default InstallSensorsNew;
